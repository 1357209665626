import React from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Style from "./news.module.css";
import SEO from "../components/SEO";
import Share from "../components/share";
import Player from "../components/mediaplayer";

export default ({ data }) => {
  const post = data.markdownRemark;
  const infos = data.site;
  const image = post.frontmatter.featureImage.image;
  return (
    <Layout imageData={data.imageData.childImageSharp.fluid}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.tags}
        image={post.frontmatter.featureImage.image.childImageSharp.fluid.src}
        pathname={post.fields.slug}
        article
      />
      <Container>
        <Row>
          <Col>
            <Link className={Style.smallFont} to="/">
              Back to frontpage
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="py-4">{post.frontmatter.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <Image fluid={image.childImageSharp.fluid} />
            {post.frontmatter.frame && (
              <div className="py-4">
                <div className="embed-responsive embed-responsive-1by1">
                  <Player frame={post.frontmatter.frame} />
                </div>
              </div>
            )}
            <div className="py-4">
              <Share
                socialConfig={{
                  twitterHandle: infos.siteMetadata.twitterHandle,
                  config: {
                    url: `${infos.siteMetadata.url}${post.fields.slug}`,
                    title: `Eingang Booking / ${post.frontmatter.title}`
                  }
                }}
                tags={post.frontmatter.tags}
              />
            </div>
          </Col>
          <Col sm="12" lg="6">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <p className={`${Style.smallFont} pt-3`}>
              Published on {post.frontmatter.date}
            </p>
            {post.frontmatter.modifyDate != post.frontmatter.date && (
              <p className={`${Style.smallFont} pt-0`}>
                Last updated on {post.frontmatter.modifyDate}
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $backgroundPath: String!) {
    imageData: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: $backgroundPath }
    ) {
      id
      relativePath
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        description
        title
        tags
        date(formatString: "DD MMMM, YYYY")
        modifyDate(formatString: "DD MMMM, YYYY")
        frame
        featureImage {
          image {
            childImageSharp {
              fluid(maxWidth: 650) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      fields {
        slug
      }
    }
    metadata: site {
      siteMetadata {
        bookingEmail
        siteUrl
      }
    }
    site {
      ...ShareInformation
    }
  }
`;
